import { FirebaseVizions } from './index'

const vizionsDb = () => {
  const add = (vizionInfo) => {
    return FirebaseVizions.add(vizionInfo)
  }

  const update = (vizionId, vizionInfo) => {
    return FirebaseVizions.doc(vizionId).update(vizionInfo)
  }

  const remove = (vizionId) => {
    return FirebaseVizions.doc(vizionId).delete()
  }

  const findOne = (vizionId) => {
    return FirebaseVizions.doc(vizionId).get()
  }

  const findAll = () => {
    return FirebaseVizions.orderBy('title')
  }
  const findAllByLO = (uid) => {
    // console.log(uid)
    return FirebaseVizions.where('LoanOfficer', '==', uid).orderBy('NickName')
  }
  const findAllByProcessor = (uid) => {
    return FirebaseVizions.where('Processor', '==', uid).orderBy('NickName')
  }
  const findAllMine = (uid) => {
    // console.log('RequestedBy.User', '==', uid)
    return FirebaseVizions.where('userId', '==', uid).where('active', '==', true).orderBy('title')
  }

  return Object.freeze({
    add,
    update,
    findOne,
    findAll,
    findAllByLO,
    findAllByProcessor,
    findAllMine,
    remove,
  })
}

const Vizions = vizionsDb()

export { Vizions }
