<template>
  <div>
    <base-section
      id="dashboard"
      class="lightgrey"
      space="0"
    >
      <div v-if="true" style="text-align:center;">
        <v-btn block @click="runtest">
          RunTest
        </v-btn>
      </div>
      <div class="col s12">
        <v-data-table
          :headers="headers"
          :items="vizions"
          :sort-by="startSortBy"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>My Vizions</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="material-icons float-left">add</i> New Vizion
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.title"
                            label="Vizion Title"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.CompanyName"
                            label="Company Name"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.level1.name"
                            :label="editedItem.level1.title"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline" style="word-break:normal;">Are you sure you want to delete this Vizion?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt.toDate() | formatDateOnly }}
          </template>
          <template v-slot:item.title="{ item }">
            <a :href="'/vizion/edit/' + item.id">{{ item.title || 'My Vizion' }}</a>
          </template>
          <template v-slot:item.output="{ item }">
            <a :href="'/vizion/display/' + item.id">View Output</a>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </base-section>
  </div>
</template>

<script>
  // import * as api from '@/functions/api.js'
  import { mapGetters } from 'vuex'
  import { Vizions } from '@/database/vizion'
  import { FirebaseResults } from '@/database'

  export default {
    name: 'SectionMap',
    data: () => ({
      vizions: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        CompanyName: '',
        title: '',
        level1: {
          title: 'CEO',
          name: '',
          answers: [],
        },
        level2: {
          title: 'COO',
          name: '',
          answers: [],
        },
        level3: {
          title: 'VP - Store Operations',
          name: '',
          answers: [],
        },
        level4: {
          title: 'VP - Operations',
          name: '',
          answers: [],
        },
        level5: {
          title: 'CIO',
          name: '',
          answers: [],
        },
        level6: {
          title: '',
          name: '',
          answers: [],
        },
        level7: {
          title: '',
          name: '',
          answers: [],
        },
        logo: {
          DocFileName: 'NoLogo.png',
          DocPath: 'NOLOGO',
          downloadURL: '/images/NoLogo.png',
        },
        createdAt: new Date(),
        lastModified: new Date(),
        userId: '',
        active: true,
      },
      defaultItem: {
        CompanyName: '',
        title: '',
        level1: {
          title: 'CEO',
          name: 'Insert Name',
          answers: [],
        },
        level2: {
          title: '',
          name: '',
          answers: [],
        },
        level3: {
          title: '',
          name: '',
          answers: [],
        },
        level4: {
          title: '',
          name: '',
          answers: [],
        },
        level5: {
          title: '',
          name: '',
          answers: [],
        },
        level6: {
          title: '',
          name: '',
          answers: [],
        },
        level7: {
          title: '',
          name: '',
          answers: [],
        },
        createdAt: new Date(),
        lastModified: new Date(),
        userId: '',
        active: true,
      },
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      }),
      formTitle () {
        return this.editedIndex === -1 ? 'New Vizion' : 'Edit Vizion'
      },
      startSortBy () {
        return 'createdAt'
      },
      headers () {
        var header = [
          // {
          //   text: 'id',
          //   align: 'start',
          //   sortable: false,
          //   value: 'id',
          // },
          { text: 'Title', value: 'title' },
          { text: 'Company Name', value: 'CompanyName' },
          { text: 'CEO', value: 'level1.name' },
          { text: 'Date', value: 'createdAt' },
          { text: 'Output', value: 'output', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
        return header
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.editedItem.userId = this.user.data.id
        this.defaultItem.userId = this.user.data.id
        this.fetchVizions()
        console.log('editedItem.userId', this.editedItem.userId)
        console.log('this.user', this.user)
      },
      runtest () {
        console.log(this.user)
        console.log(this.user.data.id)
      },
      formatNumber(val) {
        return val.toLocaleString(
          undefined, 
          { minimumFractionDigits: 2 },
        )
      },
      fetchVizions () {
        console.log('findVizions', this.user.data.id)
        Vizions.findAllMine(this.user.data.id)
        .get()
        .then(querySnapshot => {
          const results = FirebaseResults.map(querySnapshot)
          this.vizions = results
        })
      },
      deleteItem (item) {
        this.editedIndex = this.vizions.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.vizions.splice(this.editedIndex, 1)
        this.editedItem.active = false
        this.save()
        this.closeDelete()
      },
      editItem (item) {
        this.editedIndex = this.vizions.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        console.log(this.editedItem)
        if (this.editedIndex > -1) {
          Object.assign(this.vizions[this.editedIndex], this.editedItem)
          Vizions.update(this.editedItem.id, this.editedItem).then(results => {
          // console.log('Status Set.');
        })
        } else {
          Vizions.add(this.editedItem)
            .then((results) => {
              this.fetchVizions()
              console.log('Vizion created, Redirect to /Vizion/{id}')
              setTimeout(this.redirectVizion(results.id), 1000)
            })
            .catch(error => {
              console.log('error: ', error)
            })
        }
        this.close()
      },
      redirectVizion (id) {
        this.$router.push('/vizion/edit/' + id).catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      getCurrentStatus (statuses) {
        return statuses.slice().sort((a, b) => (a.StatusDate < b.StatusDate) ? 1 : -1)[0].StatusName
      },
    },
  }
</script>
